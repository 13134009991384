@use '../variables/colors';

#references {
    .gallery {
        justify-content: space-between;
    }

    .item {
        position: relative;
        margin: 0 0 20px 0;
        overflow: hidden;

        a:hover {
            * {
                color: colors.$shipGray;
            }

            .overlay__intro {
                @include transform(translate(0,100%));
            }

            .overlay__animated {
                @include transform(translate(0,0));
            }
        }
    }

    .flag {
        position: absolute;
        top: 0;
        right: 0;
    }

    .overlay__intro {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        color: colors.$white;
        background-color: rgba(0, 0, 0, 0.5);
        @include transition(all 0.3s ease);
        @include transform(translate(0,0));
        hyphens: auto;
    }

    .overlay__animated {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: rgba(252,229,0,.8);
        @include transition(all 0.3s ease);
        @include transform(translate(0,-100%));
        text-align: center;
        hyphens: auto;
    }
}

#filter {
    justify-content: space-between;
    margin-bottom: 20px;

    select, #filter-query {
        margin-bottom: 20px;
        width: fit-content;
        @include media-breakpoint-down('lg') {
            width: 100%;
        }
    }

}
