@use '../variables/colors';

.icon {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 35px 35px;

    &--search {
        background-image: url(../images/nav/search.svg);
    }

    &--account {
        background-image: url(../images/nav/profile.svg);
    }

    &--logout {
        background-image: url(../images/nav/logout.svg);
    }

    &--burger {
        background-image: url(../images/header/burger.svg);
    }

    &--download {
        background-image: url(../images/sidenav/downloads.svg);
    }

    &--cart {
        background-image: url(../images/nav/cart.svg);
    }

    &--contact {
        background-image: url(../images/nav/contact.svg);
    }

    &--wishlist {
        background-image: url(../images/nav/wishlist.svg);
    }

    &--orders {
        background-image: url(../images/nav/orders.svg);
    }
}

.btn {
    font-family: $headings-font-family;
    font-weight: bold;
}

a.btn {
    text-decoration: none;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

.btn-primary {
    display: block;
    width: fit-content;
    margin: 1.25rem 0 1.25rem 0;
    padding: 10px 50px 10px 20px;
    color: colors.$tundoraGray;
    background: colors.$turboYellow url(../images/arrow/right.svg) no-repeat right 20px center;
    background-size: 20px 20px;
    font-weight: bold;
    line-height: 1.25rem;
    white-space: nowrap;
    text-align: left;

    &.dropdown-toggle {
        width: auto;
        margin: 0;
        padding: 0;
        background: none;
    }

    &:hover, &:focus, &:active, &.active, .open > .dropdown-toggle.btn.btn-primary {
        color: colors.$scorpionGray;
        background: colors.$marigoldYellow url(../images/arrow/right.svg) no-repeat right 20px center;
        background-size: 20px 20px;
        box-shadow: none;
    }
}

.btn-secondary {
    display: block;
    width: fit-content;
    margin: 1.25rem 0 1.25rem 0;
    padding: 10px 50px 10px 20px;
    color: colors.$tundoraGray;
    background: colors.$silverGray url(../images/arrow/left.svg) no-repeat right 20px center;
    background-size: 20px 20px;
    font-weight: bold;
    line-height: 1.25rem;
    white-space: nowrap;
    text-align: left;

    &.dropdown-toggle {
        width: auto;
        margin: 0;
        padding: 0;
        background: none;
    }

    &:hover, &:focus, &:active, &.active, .open > .dropdown-toggle.btn.btn-primary {
        color: colors.$scorpionGray;
        background: colors.$altoWhite url(../images/arrow/left.svg) no-repeat right 20px center;
        background-size: 20px 20px;
        box-shadow: none;
    }
}

.full-width {
    width: 100%;
}
