@use '../variables/colors';

.page-teaser__teaser {
    display: flex;
    margin: 0 20px 20px 0;
    text-decoration: none;

    &:hover {
        color: colors.$gray;
        text-decoration: none;

        * {
            color: colors.$gray;
        }

        .page-teaser__media {
            img {
                transform: scale(1.2);
                filter: brightness(80%);
            }
        }
    }
}

.page-teaser__media {
    position: relative;
    min-width: 200px;
    height: 140px;
    margin: 0 20px 20px 0;
    border: 1px solid colors.$altoWhite;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        margin: auto;
        transform-origin: center center;
        transition: transform 1.2s, filter 1s ease-in-out;
    }
}
