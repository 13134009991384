@font-face {
    src: url('../fonts/Frutiger/443d53e8-5a36-44d4-a132-02501f29ccb4.woff2') format('woff2'),
        url('../fonts/Frutiger/e934c7fe-0eba-45c0-be91-b05ff19b5073.woff') format('woff');
    font-family: Frutiger;
    font-display: fallback;
}

@font-face {
    src: url('../fonts/FrutigerBold/d13f4ce3-6d5f-4e11-b7a6-9cde6f1624a0.woff2') format('woff2'),
        url('../fonts/FrutigerBold/9147fb15-b4da-4721-8ed8-d82293660aec.woff') format('woff');
    font-family: FrutigerBold;
    font-display: fallback;
}
