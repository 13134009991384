@include media-breakpoint-up('sm') {
    .femanager_fieldset {
        float: left;
        max-width: 50%;
        padding: 0 10px;
        white-space: nowrap;
    }
    .femanager_fieldset.femanager_country.control-group {
        clear: left;
    }
    .femanager_fieldset.femanager_address.control-group {
        clear: left;
        width: 66.6%;
    }
    .femanager_fieldset.femanager_zip.control-group {
        clear: left;
    }

    .femanager_fieldset.femanager_submit {
        width: 100%;
    }
}

.tx-femanager {
    select {
        width: 100%;
    }

    legend {
        width: max-content;
    }
}

.femanager_address {
    float: none;
    clear: both;
}
