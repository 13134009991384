.container-fluid {

    &--home-page {
        .main-nav-toggler {
            &--open {
                // Hide navigation toggler on the startpage
                display: none;
            }
        }

        .header-logo {
            margin-left: 0;
        }
    }
}
