@use '../variables/colors';

.pppopup {
    .modal-headline {
        @include font-size($h2-font-size);
        border-bottom: none;
        white-space: nowrap;
    }

    .popup-close {
        position: absolute;
        top: 16px;
        right: 16px;

        button.close {

            display: block;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            padding: 6px 0 13px;
            background: colors.$turboYellow;
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.25rem;
            white-space: nowrap;
            text-align: center;
        }
    }

    .modal-cta {
        justify-content: flex-start;
        padding-top: 20px;
        padding-right: 0;
        padding-left: 0;

        a {
            width: 100%;
            text-align: left;
        }
    }

    .modal-body-text.content-box {
        margin: 25px 0 0 0;
        padding-bottom: 0;
    }

    .modal-body.alignment-left {
        text-align: left;
    }

    .modal-content {
        border-radius: 25px;
        padding: 15px 10px 10px 10px;
    }

}
