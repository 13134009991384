@use '../variables/colors';

main {
    a {
        text-decoration: underline;

        &:hover {
            color: colors.$gray;

            * {
                color: colors.$gray;
            }
        }
    }

    h1 {
        margin-bottom: 3.75rem; // 60 pixel
    }

    h2, h3 {
        margin-bottom: 1.563rem; // 25 pixel
    }

    table {
        width: 100%;

        caption {
            caption-side: top;
        }

        &.ce-table th, &.ce-table td, th, td {
            border-top: 1px solid colors.$altoWhite;
            padding: 0.5em 0.75em;
            vertical-align: top;
        }
    }
}

.dropdown .dropdown-toggle {
    &:after {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border: none;
        vertical-align: middle;
        content: '';
        background: {
            image: url('../images/arrow/down.svg');
            repeat: no-repeat;
        }
    }
}

.dropdown.show .dropdown-toggle {
    &:after {
        background: {
            image: url('../images/arrow/up.svg');
            repeat: no-repeat;
        }
    }
}

.dropdown-item {
    &:active {
        color: colors.$black;
        background-color: colors.$wildSandWhite;
    }
}

.content-element {
    margin-bottom: 40px;
}

.content-box {
    margin: 0 0 25px 0;
    border: 1px solid colors.$altoWhite;
    border-radius: 25px;
    padding: 25px 20px 20px 20px;

    &--nav-sub {
        margin: 0 0 0 -10px;
        padding: 20px 0;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    table th {
        border-top: none;
    }
}
