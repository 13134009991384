@use '../variables/colors';

.breadcrumb-wrapper {
    min-height: 40px;
    margin-top: 20px;
    padding: 0 25px;

    &.row {
        margin-right: 0;
    }

    .breadcrumb {
        margin-bottom: 13px;
        border-style: none;
        padding: 0;

        a {
            color: colors.$silverChaliceGray;

            &:hover {
                color: colors.$shipGray;
                text-decoration: none;
            }
        }
    }
}
