@use '../variables/colors';

ul#download-accordion {
    margin: 0;
    padding: 0;

    .card {
        border: none;

        &:last-child {
            .card-header {
                border-bottom: none;
            }
        }
    }

    .card-header {
        width: 100%;
        border-bottom: 2px solid colors.$altoWhite;
        border-radius: initial;
        padding: 0 0 0 30px;
        background: transparent url(../images/arrow/down.svg) 15px 22px no-repeat;
        background-size: 14px 14px;

        &.active {
            background-color: colors.$turboYellow;
            background-image: url(../images/arrow/up.svg);

            button.btn-link {
                color: colors.$shipGray;

                &:focus, &:hover {
                    color: colors.$shipGray;
                }
            }
        }

        button.btn-link {
            width: 100%;
            padding: 18px 10px;
            color: colors.$shipGray;
            font-family: $headings-font-family;
            font-weight: bold;
            text-align: left;
            text-decoration: none;

            &:focus, &:hover {
                color: colors.$silverChaliceGray;
                text-decoration: none;
            }

            &:active {
                color: colors.$shipGray;
            }
        }
    }

    .card-body {
        padding: 0;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li.item {
            display: flex;
            align-items: baseline;
            border-bottom: 2px solid colors.$galleryWhite;
            padding: 18px 10px;

            &:nth-child(even) {
                background-color: colors.$wildSandWhite;
            }

            a {
                color: colors.$shipGray;
                text-decoration: none;

                &:focus, &:active, &:hover {
                    color: colors.$silverChaliceGray;
                    text-decoration: none;
                }

                &.btn-thumbnail {
                    margin-left: 0 !important;
                }
            }

            input[type=checkbox] {
                margin-right: 10px;
            }
        }

        .gallery {
            justify-content: space-between;
            margin: 0 10px;


            li.item {
                display: block;
                max-width: 160px;
                margin: 0;
                border-bottom: none;
                padding: 38px 0 0 0;

                &:nth-child(even) {
                    background-color: transparent;
                }

                img {
                    border: 1px solid colors.$altoWhite;
                }

                a {
                    word-break: break-all;
                }

            }

            li.filler {
                width: 160px;
            }
        }
    }

    .download-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        float: right;
    }

    .download-container__check-all {
        margin-right: 10px;
        padding-top: 10px;
        label {
            font-weight: bold;
        }

        input[type=checkbox] {
            margin-right: 10px;
        }
    }
}
