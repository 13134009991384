@use '../variables/colors';

.text-input, input[type=text], input[type=password], input[type=email], input[type=search], select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: colors.$galleryWhite;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.2);

    &:read-only {
        background-color: colors.$white;
        box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
    }
    &:focus {
        background-color: colors.$marigoldYellow;
        outline: 0;
        box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
    }
}

.form-border {
    border: 1px solid colors.$silverGray;
    border-radius: 1.25rem;
    padding: 1.25rem;
}

.product-order {

    label {
        margin-top: 1.25rem;
        margin-bottom: 10px;
    }

    h2 {
        margin-top: 1.25rem;
    }
}

.access-designer {
    input {
        margin-bottom: 1.25rem;
    }
}

ul.typo3-messages {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.alert-success {
    background-color: #f8f8f8;
    border-color: #f5f5f5;
}
