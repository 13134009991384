@use '../variables/colors';
@use '../variables/sizes';

.container-fluid--main {
    header {
        flex-shrink: 0;
        padding: 30px 25px 35px 35px;
        background-color: colors.$wildSandWhite;

        @include media-breakpoint-down('md') {
            height: sizes.$navbarWidthDesktop;
            border-bottom: solid 1px colors.$silverChaliceGray;
            padding: 25px 25px 30px 25px;
        }

        @include media-breakpoint-up('lg') {
            height: 100px;
        }

        .header-logo {
            @include media-breakpoint-down('md') {
                margin-left: 90px;
            }

            &__link {
                display: flex;

                &:hover {
                    text-decoration: none;
                }

                &:before {
                    display: inline-block;
                    width: 100px;
                    height: 43px;
                    margin-right: 8px;
                    vertical-align: -38%;
                    content: '';
                    background: {
                        image: url('../images/header/logo.svg');
                        repeat: no-repeat;
                    }
                }
            }

            &__text {
                color: colors.$black;
                @include font-size(1.25rem); // 20 pixel
                font-weight: bold;
            }
        }

        .dropdown-language .dropdown-toggle {
            padding-right: 0;
        }
    }
}
