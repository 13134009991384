@use '../variables/colors';
@use '../variables/sizes';

header, .header-partner-data, .breadcrumb-wrapper, main, .footer-main {
    // Workaround because of the navbar with fixed position
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.main-nav-toggler {
    border: none;

    &--open {
        position: absolute;
        top: 0;
        left: 0;
        width: sizes.$navbarWidthDesktop;
        height: sizes.$navbarWidthDesktop;
        border-right: solid 1px colors.$silverChaliceGray;
        border-bottom: solid 1px colors.$silverChaliceGray;
        border-radius: 0;
        padding: 25px 0 30px 0;
        background-color: colors.$galleryWhite;
    }

    &--close {
        margin: 20px;
        padding-left: 35px;
        background-color: transparent;
    }

    &__label {
        color: colors.$tundoraGray;
        font-size: $small-font-size;
        line-height: 2rem;

        &:before, &:after {
            position: absolute;
            top: 25px;
            left: 30px;
            width: 2px;
            height: 20px;
            background-color: colors.$tundoraGray;
            content: ' ';
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }

        &-sub {
            margin-left: 18px;
            color: colors.$white;
            font-size: $font-size-base;

            &:before, &:after {
                top: 12px;
                height: 18px;
                background-color: colors.$white;
            }
        }
    }

    &--sub {
        height: 40px;
        margin: 0;
    }
}

.nav-main {
    position: fixed;
    left: 0;
    z-index: 110;
    display: block;
    width: 100%;
    padding: 0;
    background-color: colors.$wildSandWhite;
    transition: all 0.2s ease-in-out;

    @include media-breakpoint-down('md') {
        &.mobile-closed {
            display: none;
        }

        &.mobile-hidden {
            // Needed to slide the menu left-right:
            left: -100%;
        }
    }

    @include media-breakpoint-up(sizes.$navbarBreakpoint) {
        flex-flow: column nowrap;
        align-items: flex-start;
        width: sizes.$navbarWidthDesktop;
        height: 100vh;
    }

    &__navbar {
        @include media-breakpoint-down('md') {
            // Scroll on the mobile menu, not on the html/body:
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
        z-index: 120;
        height: 100vh;
    }

    .nav-main__list-item--toggler {
        border-bottom: solid 1px colors.$silverChaliceGray;
    }

    .nav-main__link {
        display: block;
        @include hover-focus {
            text-decoration: none;
        }
    }
}

.nav-main__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    &--level-1 {
        background-color: colors.$galleryWhite;
        font-size: $small-font-size;

        @include media-breakpoint-up(sizes.$navbarBreakpoint) {
            flex-direction: column;
        }
    }

    &--level-2 {
        position: fixed;
        top: 0;
        z-index: 130;
        width: 100%;
        min-height: 100vh;
        border: none;
        background-color: colors.$tundoraGray;
        font-size: $font-size-base;
        transition: all 0.5s ease-in-out;

        &.closed {
            display: none;
        }

        &.hidden {
            // Needed to slide left-right:
            // @todo: transition works on destktop, but not on mobile
            @include media-breakpoint-up(sizes.$navbarBreakpoint) {
                top: -100%;
            }

            @include media-breakpoint-down('md') {
                right: -100%;
            }
        }

        @include media-breakpoint-up(sizes.$navbarBreakpoint) {
            padding-top: 60px;
        }

        @include media-breakpoint-up(sizes.$navbarBreakpoint) {
            position: absolute;
            left: sizes.$navbarWidthDesktop;
            width: 260px;
        }
    }
}

.nav-main__list-item {
    &--level-1 {
        border-bottom: solid 1px colors.$silverChaliceGray;

        @include hover-focus {
            background-color: colors.$turboYellow;
        }

        a {
            padding: 1.25rem 0.125rem;
        }

        img {
            filter: brightness(2.3);
        }

        &.active {
            .nav-main__link-label {
                color: colors.$tundoraGray;
            }

            img {
                filter: none;
            }
        }

        .nav-main__link-label {
            max-width: sizes.$navbarWidthDesktop;
            color: colors.$dustyGray;
            text-align: center;
            word-wrap: break-word;
            word-break: break-word;
            overflow-wrap: break-word;
            hyphens: auto;

            @include media-breakpoint-down(sizes.$navbarBreakpoint) {
                max-width: none;
            }
        }

        @include media-breakpoint-up(sizes.$navbarBreakpoint) {
            width: 100%;
            border-right: solid 1px colors.$silverChaliceGray;
        }
    }

    &--level-2 {
        .nav-main__link {
            @include hover-focus {
                color: colors.$tundoraGray;
                background-color: colors.$turboYellow;
            }
        }

        border: none;

        &.header {
            padding: 12px 20px;
            color: colors.$turboYellow;
            line-height: $font-size-base;
        }

        a {
            padding: 12px 20px;
            color: colors.$white;
            line-height: $font-size-base;
        }
    }
}

.nav-main__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    display: none;
    height: 100vh;
    margin-left: sizes.$navbarWidthDesktop;
    background: rgba(87, 87, 86, 0.6);

    &.active {
        display: block;
    }
}
