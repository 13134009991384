@use '../variables/colors';
@use '../variables/sizes';

body.tmpl-thmdefault {
    @include media-breakpoint-up(sizes.$navbarBreakpoint) {
        // Left margin for the navigation is required on a regular page only,
        // not on the home page, where the navigation is not present
        margin-left: sizes.$navbarWidthDesktop;
    }
}

.container-fluid {
    // Push the footer to the bottom
    &--main {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding: 0;
    }

    &--sub-page {
        .main-nav-toggler {
            &--open {
                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
            }
        }
    }
}

.header-partner-data {
    min-height: 40px;
    padding: 0 25px;
    background-color: colors.$altoWhite;
    line-height: 40px;

    &.row {
        margin-right: 0;
    }

    span {
        padding: 12px 0;
    }

    a {
        &:hover {
            color: colors.$black;
            text-decoration: none;
        }
    }
}

// Wrapper set in thmpartner/Configuration/TypoScript/Setup/Base/200-Library/Content.ts1:11
.content-main-inner {
    width: 100%;

    // Compensate for the Online chat bar right
    margin-right: 40px;
}

main {
    flex-grow: 1;
    width: 100%;
    max-width: 1158px;

    padding: 35px 35px 60px 35px;

    @include media-breakpoint-down('xs') {
        padding: 10px 10px 25px 10px;
    }
}
