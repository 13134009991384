@use '../variables/colors';

#tx-solr-search {
    .results-highlight {
        background-color: colors.$turboYellow;
    }

    ol.results-list {
        padding: 0;
        counter-reset: LIST-ITEMS;

        li {
            display: block;
        }
    }

    .results-entry {
        display: block;
        a {
            text-decoration: none;
        }
    }

    .results-topic {
        display: inline;
        @include font-size(1rem); // 16 pixel
    }
}
