@use '../variables/colors';

.news-detail {
    align-content: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 1.25rem;

    img {
        display: block;
        width: 100%;
        max-width: 300px;
        height: auto;
        margin-bottom: 1.25rem;
    }

    &-body {
        border: 1px solid colors.$silverGray;
        border-radius: 1.25rem;
        padding: 2.5rem 1.25rem;

        .mediaelement {
            video {
                width: 100%;
            }
        }
    }
}

.news-list {
    align-content: center;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid colors.$silverGray;
    border-radius: 1.25rem;
    padding: 2.5rem 1.25rem;

    *, *:hover {
        text-decoration: none;
    }

    .title {
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-wrap: break-word;
    }

    .news-item {
        margin-bottom: 1.25rem;

        &:hover {
            .news-media {
                img {
                    transform: scale(1.2);
                    filter: brightness(80%);
                }
            }
        }
    }

    .news-item-body {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;

        h3 {
            margin-top: 1.25rem;
        }
    }

    .news-media {
        overflow: hidden;
        height: 140px;
        width: auto;
        position: relative;
        @include media-breakpoint-down('lg') {
            height: 100px;
        }
        @include media-breakpoint-between('sm', 'md') {
            height: 140px;
        }
        @include media-breakpoint-down('sm') {
            height: 300px;
            width: 100%;
        }


        img {
            display: block;
            left: -100%;
            right: -100%;
            top: -100%;
            bottom: -100%;
            margin: auto;
            height: auto;
            width: auto;
            position: absolute;
            transform-origin: center center;
            transition: transform 1.2s, filter 1s ease-in-out;
            @include media-breakpoint-down('sm') {
                width: 100%;
            }
        }

    }
}

.quicklinks {
    @extend .news-list;
    margin-bottom: 20px;
    padding: 0;

    a {
        display: block;
        padding: 25px 20px;
    }

    *, *:hover {
        text-decoration: none;
    }

    .news-item {
        margin-bottom: 0;
    }

    .news-media {
        margin-bottom: 1.25rem;
    }
}

.news-list-teaser {
    @extend .news-list;
    padding-bottom: 0;

    .news-media {
        @include media-breakpoint-between('sm', 'lg') {
            height: 140px;
        }
        @include media-breakpoint-down('md') {
            height: 200px;
        }
        img {
            width: 100%;
        }
    }

    *, *:hover {
        text-decoration: none;
    }

    .news-item {
        margin-bottom: 1.25rem;
    }
}

.pagination {
    align-content: center;
    margin-top: 1.25rem;

    a, a:hover {
        text-decoration: none;
    }

    .arrow {
        display: inline-block;
        width: 40px;
        height: 40px;
        border: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 40px 40px;

        &-double-left {
            background-image: url(../images/nav-elements/doublearrow/left.svg);

            &:hover {
                background-image: url(../images/nav-elements/doublearrow/left-inactive.svg);
            }
        }

        &-left {
            background-image: url(../images/nav-elements/arrow/left.svg);

            &:hover {
                background-image: url(../images/nav-elements/arrow/left-inactive.svg);
            }
        }

        &-double-right {
            background-image: url(../images/nav-elements/doublearrow/right.svg);

            &:hover {
                background-image: url(../images/nav-elements/doublearrow/right-inactive.svg);
            }
        }

        &-right {
            background-image: url(../images/nav-elements/arrow/right.svg);

            &:hover {
                background-image: url(../images/nav-elements/arrow/right-inactive.svg);
            }
        }
    }

    .page-item:hover {
        .arrow {

            &-double-left {

                background-image: url(../images/nav-elements/doublearrow/left-inactive.svg);
            }

            &-left {

                background-image: url(../images/nav-elements/arrow/left-inactive.svg);
            }

            &-double-right {

                background-image: url(../images/nav-elements/doublearrow/right-inactive.svg);
            }

            &-right {

                background-image: url(../images/nav-elements/arrow/right-inactive.svg);
            }
        }
    }

    .page-item.active {
        .page-link {
            color: colors.$black;

            &:hover {
                background-color: colors.$marigoldYellow;
            }
        }
    }

    .page-item.disabled {
        .arrow {

            &-double-left {
                background-image: url(../images/nav-elements/doublearrow/left-inactive.svg);
            }

            &-left {
                background-image: url(../images/nav-elements/arrow/left-inactive.svg);
            }

            &-double-right {
                background-image: url(../images/nav-elements/doublearrow/right-inactive.svg);
            }

            &-right {
                background-image: url(../images/nav-elements/arrow/right-inactive.svg);
            }
        }

        .page-link {
            background-color: colors.$galleryWhite;
        }
    }

    .page-link {
        min-width: 40px;
        max-width: 46px;
        height: 40px;
        margin-right: 2.5px;
        margin-left: 2.5px;
        border: none;
        border-radius: 5px;
        padding: 6px 8px 8px;
        color: colors.$black;
        background-color: colors.$altoWhite;
        text-align: center;
        vertical-align: middle;

        &:hover {
            color: colors.$gray;
            background-color: colors.$galleryWhite;
        }

        &.icon {
            padding: 0;
        }
    }
}
