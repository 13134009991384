@use '../variables/colors';

#tx_evvadatasheet {
    legend {
        font-family: $headings-font-family;
        font-size: 1rem;
        font-weight: bold;
    }

    button {
        width: fit-content;
    }

    table {
        margin-bottom: 20px;

        tr {
            td {
                border-top-width: 2px;
                border-color: colors.$gray!important;
                border-bottom: 2px solid colors.$gray;

                p {
                    margin-bottom: 0;
                }
            }

            &.mod td {
                background-color: colors.$altoWhite;
            }

            &.opt td {
                background-color: colors.$galleryWhite;
            }
        }
    }

    .gallery {
        .item {
            margin: 0 25px 25px 0;

            img {
                border: 1px solid colors.$altoWhite;
                border-radius: 12px;
            }
        }
    }

    .content-box {
        &.tm_drawing {
            margin: 25px 0;
            border-radius: 12px !important;
        }
    }

    p.version {
        color: colors.$silverChaliceGray;
    }
}
