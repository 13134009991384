@use '../variables/colors';

.nav-sub {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    &__list {
        &-item {
            border: none;
            padding: 12px 0 12px 20px;
        }
    }

    a {
        text-decoration: none;

        // @todo reolve duplication of hover styling
        &:hover {
            color: colors.$gray;
        }
    }
}
