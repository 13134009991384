@use '../variables/colors';
header.d-flex.row {
    background: transparent;
}

.login-box {
    position: absolute;
    left: auto;
    align-content: center;
    width: 70%;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid colors.$silverGray;
    border-radius: 1.25rem;
    padding: 1.25rem;
    background-color: colors.$white;

    @media (min-width: 992px) {
        position: absolute;
        left: -1%;
    }

    @media (min-width: 1280px) {
        left: -5%;
    }

    @media (min-width: 1480px) {
        left: -10%;
    }

    @media (min-width: 1680px) {
        left: -15%;
    }

    @media (min-width: 1880px) {
        left: -20%;
    }

    @media (min-width: 2080px) {
        left: -25%;
    }

    @media (min-width: 2280px) {
        left: -30%;
    }

    @include media-breakpoint-between(xs, sm) {
        width: 90%;
    }

    *, *:hover {
        text-decoration: none;
    }

    h1 {
        margin-bottom: 1.25rem;
    }

    input {
        width: 100%;
        border: 1px solid colors.$silverGray;
        border-radius: 5px;
        padding: 5px 10px;
        background-color: colors.$galleryWhite;
        box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);

        &:focus {
            border: 1px solid colors.$silverGray;
            border-radius: 5px;
            background-color: colors.$galleryWhite;
            outline: 0;
            box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .alert {
        margin-bottom: 1.25rem;
        border-radius: 1.25rem;
    }

    .card-deck {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-flow: row wrap;
            margin-right: -$card-deck-margin;
            margin-left: -$card-deck-margin;
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin: 0;
        }
    }

    // Place general rules for the card outside of card-deck, since both layouts are possible: two-cols, one-col
    .card {
        border: none;

        .card-header {
            border-bottom: none;
            padding: 0 0 140px;
            background-color: transparent;

            .card-btn {
                position: absolute;
                bottom: 70px;
                left: 0;
                z-index: 100;

                @include media-breakpoint-down(md) {
                    &#register-button {
                        position: relative;
                        bottom: 0;
                    }
                }
            }
        }

        .card-body {
            position: relative;
            padding: 0 20px;
        }

        .card-footer {
            border-top: none;
            padding: 0 20px;
            background: transparent;
        }

        .card-body, .card-footer {
            .link-wrapper {
                a {
                    font-size: 1rem;
                    line-height: 1rem;

                    &:before {
                        background: {
                            // @TODO: Replace with yellow arrow
                            image: url(../images/arrow-light/right.svg);
                            repeat: no-repeat;
                        }
                        display: inline-block;
                        width: 0.75rem;
                        height: 0.75rem;
                        content: '';
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            flex: 1 0 0;
            margin-right: $card-deck-margin;
            margin-bottom: 0;
            margin-left: $card-deck-margin;
        }
    }

    // Special styling for login-box only:
    .card--login {
        .tx-felogin-pi1 {
            display: flex;
            flex-direction: column;
            height: 100%;

            .card-body {
                .link-wrapper--back-to-login {
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 10px;
                }
            }
        }

        .card-header {
            form {
                width: 100%;
                border: 1px solid colors.$silverGray;
                border-radius: 1.25rem;
                padding: 1.25rem 1.25rem 0.25rem 1.25rem;
            }
        }

        h3 {
            margin-bottom: 10px;
        }
    }

    // Special styling for editorial box only:
    .card--editorial {
        .editorial-box {
            padding: 1.25rem 1.25rem 1.25rem 0;

            @include media-breakpoint-down(md) {
                margin-top: 20px;
                border-top: 1px solid colors.$silverGray;
            }

            .content-main-inner {
                margin-right: 0;
            }

            .content-element {
                margin-bottom: 0;
            }
        }

        .card-header {
            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }

            .card-btn {

                @include media-breakpoint-down(md) {
                    position: relative;
                    bottom: 0;
                }

                &--no-deck {
                    position: relative;
                    bottom: 0;
                }
            }

            &--no-deck {
                padding-top: 20px;
                padding-bottom: 0;
            }
        }
    }

    // Special styling for (combination of) special cases:
    // - Login-box contains alert (class set via JS);
    // - Login-box does not contain a form (class set via JS);
    // - Two-columns layout (with editorial text)
    // - One-column layout (=card--no-deck)
    // !important is needed, otherwise it won't work.
    &--has-no-form {
        .card {
            .link-wrapper--back-to-login {
                @include media-breakpoint-down(md) {
                    position: relative !important;
                }
                @include media-breakpoint-up(lg) {
                    position: absolute !important;
                }
            }

            .card-btn {
                bottom: 0 !important;
            }

            .card-header {
                @include media-breakpoint-down(md) {
                    padding-bottom: 0 !important;
                }
                @include media-breakpoint-up(lg) {
                    padding-bottom: 40px !important;
                }
            }

            &--no-deck {
                .link-wrapper--back-to-login {
                    position: relative !important;
                }

                .card-header {
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    &--has-alert {
        .editorial-box {
            @include media-breakpoint-down(md) {
                padding-top: 20px !important;
            }
            @include media-breakpoint-up(lg) {
                padding-top: 0 !important;
            }
        }
    }
}
