@use '../variables/colors';

.meta-navigation {
    .list-group-item {
        border: 0;
        background-color: transparent;

        a {
            display: flex;
            align-items: center;

            &:hover {
                text-decoration: none;
            }

            .icon {
                margin-right: 10px;
            }
        }
    }

    &--header {
        .list-group-item {
            padding-top: 0;
            padding-bottom: 0;
        }

        .list-group--eos {
            .list-group-item {
                padding: 0;

                .list-group-item--label {
                    display: none;
                }
            }
        }
    }

    &--nav-main {
        padding: 30px;
    }
}
