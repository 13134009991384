/*
Images, Text with images, Text with media
 */

.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
    margin-bottom: 20px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 20px;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 20px;
}

.ce-below .ce-gallery {
    margin-top: 20px;
}
