$turboYellow: #fce500; // RGB 252/229/0
$marigoldYellow: #f9ee7b; // RGB 249/238/123
$black: #000; // RGB 0/0/0
$shipGray: #3d3d3f; // RGB 61/61/63
$tundoraGray: #4a4a4a; // RGB  74/74/74
$scorpionGray: #575757; // RGB 87/87/87
$nevadaGray: #6f7072; // RGB 111/112/114
$gray: #828282; // RGB 130/130/130
$dustyGray: #9b9b9b; // RGB 155/155/155
$silverChaliceGray: #b2b2b2; // RGB 178/178/178
$silverGray: #ccc; // RGB 204, 204, 204
$altoWhite: #dadada; // RGB 218/218/218
$galleryWhite: #ededed; // RGB 237/237/237
$wildSandWhite: #f6f6f6; // RGB 246/246/246
$white: #fff; // RGB 0/0/0
