@use '../variables/colors';

.ce-teaser {
    &--header {
        margin: -25px -20px 0 -20px;
        border-bottom: 1px solid colors.$altoWhite;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding: 25px 20px 20px 20px;
    }

    &__layout-11 {
        .ce-teaser--header {
            background-color: colors.$turboYellow;
        }
    }

    &__layout-12 {
        .ce-teaser--header {
            background-color: colors.$silverGray;
        }
    }

    &__layout-13 {
        .ce-teaser--header {
            background-color: colors.$dustyGray;
        }
    }

    &--content {
        margin-top: 20px;

        &__image {
            width: 100%;
            margin: 20px 0;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

}
