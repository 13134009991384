@use '../variables/colors';

.footer-main {
    flex-shrink: 0;
    width: 100%;
    min-height: 60px;
    background-color: colors.$altoWhite;

    &.row {
        margin: 0;
    }

    &.login {
        background-color: transparent;

        .list-group-item {
            background-color: transparent;
        }
    }
    .list-group {
        align-items: center;
        height: 100%; // Fill the whole footer
    }

    .list-group-item {
        border-style: none;
        background-color: colors.$altoWhite;

        a {
            &:hover {
                color: colors.$black;
                text-decoration: none;
            }
        }

        @include media-breakpoint-down('xs') {
            padding-bottom: 0;
        }
    }
}
