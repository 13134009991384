@use 'variables/colors';
/**
 * Variables appear in the same order as in _variables.scss of Bootstrap.
 */

/* stylelint-disable scss/dollar-variable-pattern */
// Color System
$gray-100: #f6f6f6;
$gray-200: #ededed;
$gray-300: #dadada;
$gray-400: #b2b2b2;
$gray-500: #9b9b9b;
$gray-600: #575757;
$gray-700: #4a4a4a;
$primary: colors.$turboYellow;
$secondary: $gray-300;

// Container width
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1440px
);

// Options
$enable-deprecation-messages: true;

// Body
$body-color: #4a4a4a; // corporate colour RGB 74/74/74

// Grid columns
$grid-gutter-width: 20px;

// Typography
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$small-font-size: 0.875rem; // 14 pixel
$font-family-sans-serif: Frutiger, sans-serif;
$headings-font-family: FrutigerBold, sans-serif;
$h1-font-size: $font-size-base * 2.5; // 40 pixel
$h2-font-size: $font-size-base * 1.5; // 25 pixel
$h3-font-size: $font-size-base * 1.125; // 18 pixel

// Button
$btn-border-width: 0;
$btn-border-radius: 20px;
$btn-padding-x: 20px;
$btn-padding-y: 6px;

// Breadcrumbs
$breadcrumb-bg: colors.$white;
$breadcrumb-divider-color: colors.$silverChaliceGray;
$breadcrumb-active-color: colors.$shipGray;
$breadcrumb-divider: quote('>');

// Content
$paragraph-margin-bottom: 1.563rem;

// Modal

$modal-content-border-radius: 20px;
$modal-header-border-width: 0;
$modal-header-padding: 0;
$modal-header-padding-y: 2rem;
$modal-header-padding-x: 2rem;
$modal-dialog-margin: 3rem;

/* stylelint-enable scss/dollar-variable-pattern */
