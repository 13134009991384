@import "~lity/dist/lity.min.css";

.playVideo {
    cursor: pointer;
}

.playVideo.videoPlayIcon {
    background-position: center;
    background-image: url(../images/video/video-play-btn-thumb-overlay_s.png);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 100;
    display: inline-block;
    background-size: contain;
    width: 184px;
    height: 130px;
}


.playVideo.videoPlayNotVisibleDiv {
    width: 184px;
    height: 130px;
    position: absolute;
    z-index: 100;
    display: inline-block;
}

.playVideo.videoPlayNotVisibleDiv:hover {
    background-position: center center;
    background-image: url(../images/video/video-play-btn-thumb-overlay_s.png);
    background-size: contain;
    background-repeat: no-repeat;
}


video {
    width: 100%;
    height: auto;
}

.mediaelement-video video,iframe {
    border: 1px solid #ccc;
    border-radius: 1.25rem;
}
.mediaelement-video video:focus {
    outline: 0px;
}

.videoPreviewStartPage {
    max-width: 100%;
    height: 150px;
}

.vjs-loading-spinner, .vjs-text-track-display, .vjs-modal-dialog, .vjs-control-bar {
    display: none;
}

.news-detail-body .mediaelement-video {
    position: relative;
    padding-bottom: 70%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.news-detail-body .mediaelement-video video {
    width: 100% !important;
    height: auto !important;
}

.news-detail-body .mediaelement-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

