[class^="country-"] {
    display: inline-block;
    width: 1.6875rem;
    height: 1rem;
    margin-right: 0.5rem;
    background-repeat: no-repeat;
    background-size: 1.6875rem 1rem;
    vertical-align: middle;
}

.country-0 {
    background-image: url('../images/countries/country-0.svg');
}

.country-1 {
    background-image: url('../images/countries/country-1.svg');
}

.country-2 {
    background-image: url('../images/countries/country-2.svg');
}

.country-3 {
    background-image: url('../images/countries/country-3.svg');
}

.country-4 {
    background-image: url('../images/countries/country-4.svg');
}

.country-6 {
    background-image: url('../images/countries/country-6.svg');
}

.country-7 {
    background-image: url('../images/countries/country-7.svg');
}

.country-8 {
    background-image: url('../images/countries/country-8.svg');
}
