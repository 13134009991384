#partner-order-list {
    table thead tr .header {
        cursor: pointer !important;
    }

    th.tablesorter-header {
        white-space: nowrap;
        vertical-align: top;
        outline: none;
        cursor: pointer;

        .tablesorter-header-inner {
            padding-right: 40px;
            background: url("../images/assets/sort_arrows.png") top right no-repeat;
            vertical-align: top;
            cursor: pointer !important;
        }
    }

    th.sorter-false {
        cursor: default;

        .tablesorter-header-inner {
            background: none !important;

        }
    }

    td a {
        padding-left: 20px;
        background: url("../images/arrow/arrow-double-grey.svg") left center no-repeat;
        background-size: 19px 14px;
        text-decoration: underline;
        vertical-align: middle;
    }
}

#page-search {
    display: none;
}

a.help {
    width: 18px;
    height: 18px;
    padding-right: 18px; //
    background: url("../images/assets/help.svg") top right no-repeat;
    opacity: 0.5;
}
