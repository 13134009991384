@use 'variables/colors';

body, html {
    height: 100%;
    color: colors.$tundoraGray;
    font-family: $font-family-sans-serif;
}

strong {
    font-family: $headings-font-family;
    font-weight: bold;
}

@include media-breakpoint-down('md') {
    // Scroll within menu mobole, not within html/body
    html {
        &.no-scroll {
            overflow: hidden;
        }
    }

    body {
        &.no-scroll {
            height: 100%;
            overflow: visible;
        }
    }
}

a, span, p, h1, h2, h3, h4, h5 {
    color: colors.$tundoraGray;
}

a {
    &:hover, &:focus {
        color: colors.$black;
    }
}

.allow-word-break {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}
